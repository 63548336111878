
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import ExportOptions from "@/components/export-options.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "@/services/filter-service";
import Moment from "moment";
import PopUp from "@/components/PopUp.vue";
import purchaseServices from "@/services/purchase-services";

@Component({
    components: {
        Header,
        Sidebar,
        Loader,
        ExportOptions,
        FilterComponent,
        PopUp,

    },
})
export default class PurchaseQuotationsList extends Vue {
    loading = true;
    sidebarVisible = false;
    purchaseItems: any = []
    other_info: any = []
    charges_deduct_info: any = []
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    // modal end
    public fields = [
        { key: "quotation_no", sortable: false, label: "Purchase Quotation No" },
        { key: "quotation_date", sortable: false, label: "Date" },
        { key: "executive_title", sortable: false, label: "Executive" },
        { key: "transaction_title", sortable: false, label: "Transaction" },
        { key: "transaction_rate", sortable: false, label: "Transaction Rate" },
        { key: "supplier_title", sortable: false, label: "Supplier" },
        { key: "attachment", sortable: false, label: "Document" },
        { key: "vat_type", sortable: false, label: "VAT Type" },
        { key: "status", sortable: false, label: "Status" },
        { key: 'created_by_username', label: "Created By" },
        { key: "comment", sortable: false, label: "Remarks" },
        { key: 'action', label: 'Action' },
    ];
    public itemsFields = [
        { key: "item_master_title", sortable: false, label: "Item" },
        { key: "desc", sortable: false, label: "Description" },
        { key: "no_of_pieces", label: "Unit" },
        { key: "qty", sortable: false, label: "Quantity" },
        { key: "unit_rate", sortable: false, label: "Unit Rate" },
        { key: "total_excl_vat", sortable: false, label: "Total Excl. VAT" },
        { key: "disc_amount", sortable: false, label: "Disc Amount" },
        // { key: "project_title", sortable: false, label: "Tax Structure" },
        { key: "tax_amount", sortable: false, label: "Tax Amount" },
        // { key: "project_title", sortable: false, label: "Total Amount with Tax" },
        { key: "project_title", sortable: false, label: "Project" },
        { key: "net_amount", sortable: false, label: "Net Amount" },
        { key: "item_type", sortable: false, label: "Item Type" },
        { key: "comment", sortable: false, label: "Comments" },
    ];
    public otherInfoFields = [
        { key: "ref_bill_no", sortable: false, label: "Ref Bill No" },
        { key: "ref_bill_date", sortable: false, label: "Ref Bill Date" },
        { key: "info_1", sortable: false, label: "Other Info 1" },
        { key: "info_2", sortable: false, label: "Other Info 2" },
        { key: "info_3", sortable: false, label: "Other Info 3" },
        { key: "info_4", sortable: false, label: "Other Info 4" },
        { key: "info_5", sortable: false, label: "Other Info 5" },
    ];
    public chargesAndDeduction = [
        { key: "account_code", sortable: false, label: "Account Code" },
        { key: "account_title", sortable: false, label: "Account" },
        { key: "ammount", sortable: false, label: "Amount" },
        { key: "charges", sortable: false, label: "Charges" },
        { key: "deduction", sortable: false, label: "Deduction" },
        { key: "project_title", sortable: false, label: "Project" },
        { key: "comments", sortable: false, label: "Comment" },
    ];
    public columns = [
        {
            label: "Code",
            field: "code",
        },
    ];
    public widths = [55, 100, 55, 100, 70, 80];

    public option_dept: any = [];
    public selected_dept = 0;
    public option_emptype: any = [];
    public selected_emptype = 0;
    public option_branch: any = [];
    public selected_branch = 0;
    public option_bloodgroup: any = [];
    public selected_bloodgroup = 0;
    public selected_effective_date_from = "";
    public selected_effective_date_to = "";
    public selected_items = [];
    public items = [];
    public currentPage = 1;
    get rows() {
        return this.selected_items.length;
    }
    public import_file: any = null;

    retrieve() {
        this.loading = true;
        purchaseServices.getAllQuotationRequest().then((response) => {
            this.selected_items = response.data;
            this.loading = false
        }).catch((e) => {
            console.log(e);
            this.loading = false
        });
    }
    handleButtonClick(data: any) {
        this.purchaseItems = data.items;
        this.other_info = data.purchase_info;
        this.charges_deduct_info = data.charges_and_deduction;
        this.sidebarVisible = true;
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-details");
    }
    mounted() {
        this.retrieve();
    }
    onRowClicked() {

    }

    filter_fields = [
        { type: "date", label: "Date", name: "date", value: "", param_name: "quotation_date", filter_type: "simple", },
        { type: "text", label: "Purchase Quotation No", name: "no", value: "", param_name: "quotation_no", filter_type: "simple", },
        // { type: "text", label: "Executive", name: "executive", value: "", param_name: "executive", filter_type: "simple", },
        // { type: "text", label: "Transaction", name: "job_no", value: "", param_name: "transaction", filter_type: "simple", },
        // { type: "number", label: "Transaction Rate", name: "job_no", value: "", param_name: "transaction", filter_type: "simple", },
        // { type: "text", label: "Supplier", name: "supplier", value: "", param_name: "cost_center", filter_type: "simple", },
        { type: "dropdown",label: "VAT Type",name: "vat_type",value: "",options: ["5%", "10%", "15%", "20%"],param_name: "vat_type",filter_type: "simple",},
        { type: "dropdown",label: "Status",name: "status",value: "",options: ["draft", "submitted", "approved", "cancelled"],param_name: "status",filter_type: "simple",},
        // { type: "text", label: "Created By", name: "created_by", value: "", param_name: "created_by", filter_type: "simple", },
    ];
    advance_filter_fields = [
        { type: "date",label: "Date",min_param_name: "start_quotation_date",max_param_name: "end_quotation_date",start: "",end: "",range: [],filter_type: "advance",},
            ];

            handleFilterChange(element: any) {
        if (element.filter_type === "simple") {
            interface SimpleObj {
                [key: string]: any;
            }
            const simpleFilteredObj: SimpleObj = {};
            this.filter_fields.forEach((filter) => {
                simpleFilteredObj[filter.param_name] = filter.value;
            });
            this.loading = true;
            FilterService.getPurhcase(simpleFilteredObj,"quotation").then((data) => {
                if (data.data.purchase_quotations !== undefined && data.data.purchase_quotations !== null) {
                    this.selected_items = data.data.purchase_quotations;
                    this.loading = false;
                } else {
                    this.retrieve();
                }
            });
        }
         else if (element.filter_type === "advance") {
            interface AdvanceObj {
                [key: string]: any;
            }
            const advanceFilteredObj: AdvanceObj = {};
            this.advance_filter_fields.forEach((filter) => {
                if (filter.type === "date") {
                    if (filter.min_param_name && filter.range) {
                        const date0 = new Date(filter.range[0]);
                        const formattedDate0 = Moment(date0).format("YYYY-MM-DD");
                        advanceFilteredObj[filter.min_param_name] =
                            formattedDate0 === "Invalid date" ? "" : formattedDate0;
                    }
                    if (filter.max_param_name && filter.range) {
                        const date1 = new Date(filter.range[1]);
                        const formattedDate1 = Moment(date1).format("YYYY-MM-DD");
                        advanceFilteredObj[filter.max_param_name] =
                            formattedDate1 === "Invalid date" ? "" : formattedDate1;
                    }
                }
            });
            this.loading = true;
            FilterService.getPurhcase(advanceFilteredObj,"quotation").then((data) => {
                if (data.data.purchase_quotations !== undefined && data.data.purchase_quotations !== null) {
                    this.selected_items = data.data.purchase_quotations;
                    this.loading = false;
                } else {
                    this.retrieve();
                }
            });
        }
    }
    refreshFilters() {
        for (let field of this.filter_fields) {
            field.value = "";
        }
        this.retrieve();
    }
    refreshAdvanceFields() {
        for (let field of this.advance_filter_fields) {
            if(field.type === "date"){
        field.range = [];
        field.start = '';
        field.end= '';
      }
            this.retrieve();
        }
    }
    downloadDocument(url: string) {
        const anchor = document.createElement("a");
        const fileUrl = process.env.VUE_APP_SERVER + url;

        // Check if the URL is valid and the file exists
        fetch(fileUrl)
            .then(response => {
                if (response.status === 200) {
                    // The file exists, so we can proceed with the download
                    anchor.href = fileUrl;
                    anchor.download = "downloaded_file_name"; // You can specify the name of the downloaded file here
                    anchor.target = "_blank";

                    document.body.appendChild(anchor);
                    anchor.click();

                    document.body.removeChild(anchor);
                    this.showModal("File Downloaded", [], "success")
                } else {
                    this.showModal('File not found or URL is not valid.', [], "error")
                    // Handle the case where the file does not exist
                }
            })
            .catch(error => {
                // Handle other network-related errors
                this.showModal("An error occurred while fetching the file.", [], "error");
            });
    }
    getStatusClass(status: any) {
        if (status === 'In Progress') {
            return 'status-pill orange';
        } else if (status === 'approved') {
            return 'status-pill green';
        }
        else if (status === 'cancelled') {
            return 'status-pill red';
        }
        else {
            return 'status-pill'; // Default styling if status doesn't match any condition
        }
    }
}
